import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { DI_DATA } from '../mocks/mock';
import { PageService } from './page.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private storedParams: {};
  constructor(private router: Router, private http: HttpClient, private pageService: PageService) { }

  /**
   * [getDI for the given parameters from api]
   * @param  params [description]
   * @return        [description]
   * @author SapnilP
   * @task QLIKTAG-2925
   */
  getDI(params: any, queryParams: Params = {}): Observable<any> {
    let httpParams = new HttpParams({ fromObject: queryParams });
    httpParams = httpParams.delete('eid').delete('iid');

    let urlParams = `${params.iid}`;
    if (params.eid) {
      urlParams = `${urlParams}/${params.eid}`;
    }

    //debug starts
    // const response = DI_DATA;
    // const { data } = response
    // return of(
    //   {
    //     status: response.status,
    //     diLayout: response.status === 'activated' ? data.layout : data.inactiveLayout,
    //     diData: data.entityInformation,
    //     customGetAPIData: data.customGetAPI,
    //     diLanguages: data.diLanguages,
    //     diPrimaryLanguage: data.diPrimaryLanguage,
    //     environment: data.environment,
    //     controllerCode: data.controllerCode,
    //     ditemplateId: data.ditemplateId,

    //   }
    // )
    //debug ends

    return this.get(`di/data/template/${urlParams}`, httpParams)
      .pipe(
        map(response => {
          const data = response.data;
          // return {
          //   status: response.status,
          //   diLayout: response.status === 'activated' ? data.layout : data.inactiveLayout,
          //   diData: data.entityInformation,
          //   customGetAPIData: data.customGetAPI,
          //   diLanguages: data.diLanguages,
          //   diPrimaryLanguage: data.diPrimaryLanguage,
          //   environment: data.environment,
          //   controllerCode: data.controllerCode,
          //   ditemplateId: data.ditemplateId,
          //   authDomain: data.authDomain,
          // };
          return {vdi: {
            status: response.status,
            diLayout :  queryParams['preview'] === 'true' && response.status === 'activated' ? data.draftLayout :response.status !== 'activated' ? data.inactiveLayout : data.layout,
            diData: data.entityInformation,
            customGetAPIData: data.customGetAPI,
            diLanguages: data.diLanguages,
            diPrimaryLanguage: data.diPrimaryLanguage,
            environment: data.environment,
            controllerCode: data.controllerCode,
            ditemplateId: data.ditemplateId,
            authDomain: data.authDomain,
          },
          nfc:{
            nfc:{
              ntag_424_dna: {
                data: data.nfcTagDetails
            }
            } 
          }};
        }),
        catchError((error) => {
          // console.log(error);
          return of({});
        })
      );
  }

  /**
   * [ getDIByShortCode - Get template and entity data when shortcode is present ]
   * @param  params [ params object ]
   * @return        [ vdi as Observable ]
   * @author SapnilP
   * @task QLIKTAG-3040
   */
  getDIByShortCode(params: any, queryParams: Params = {},  thirdWebParams: any = {}): Observable<any> {
    sessionStorage.setItem('lastApiCall', 'getDIByShortCode');
    let combinedParams = {}
    if(thirdWebParams  && Object.keys(thirdWebParams).length > 0){
      params = this.storedParams;
      queryParams = thirdWebParams
      const httpParams = new HttpParams({ fromObject: queryParams });
      return this.get(`di/data/${params.shortcode}`, httpParams)
      .pipe(
        map(response => {
          const data = response.data;
          return {vdi: {
            status: response.status,
            diLayout: response.status === 'activated' ? data.layout : data.inactiveLayout,
            diData: data.entityInformation,
            customGetAPIData: data.customGetAPI,
            diLanguages: data.diLanguages,
            diPrimaryLanguage: data.diPrimaryLanguage,
            environment: data.environment,
            controllerCode: data.controllerCode,
            NftVerificationResult:data.NftVerificationResult,
            ditemplateId: data.ditemplateId,
            authDomain: data.authDomain,
          },
          nfc:{
            nfc:{
              ntag_424_dna: {
                data: data.nfcTagDetails
            }
            } 
          }};
        }),
        catchError((error) => {
          // console.log(error);
          return of({});
        })
      );
    }else{
      this.storedParams = params;
      const httpParams = new HttpParams({ fromObject: queryParams });
      return this.get(`di/data/${params.shortcode}`, httpParams)
      .pipe(
        map(response => {
          const data = response.data;
          // return {
          //   status: response.status,
          //   diLayout: response.status === 'activated' ? data.layout : data.inactiveLayout,
          //   diData: data.entityInformation,
          //   customGetAPIData: data.customGetAPI,
          //   diLanguages: data.diLanguages,
          //   diPrimaryLanguage: data.diPrimaryLanguage,
          //   environment: data.environment,
          //   controllerCode: data.controllerCode,
          //   ditemplateId: data.ditemplateId,
          //   authDomain: data.authDomain,
          // };
          return {vdi: {
            status: response.status,
            diLayout: response.status === 'activated' ? data.layout : data.inactiveLayout,
            diData: data.entityInformation,
            customGetAPIData: data.customGetAPI,
            diLanguages: data.diLanguages,
            diPrimaryLanguage: data.diPrimaryLanguage,
            environment: data.environment,
            controllerCode: data.controllerCode,
            ditemplateId: data.ditemplateId,
            authDomain: data.authDomain,
          },
          nfc:{
            nfc:{
              ntag_424_dna: {
                data: data.nfcTagDetails
            }
            } 
          }};
        }),
        catchError((error) => {
          // console.log(error);
          return of({});
        })
      );
    }
    
  }

  /**
   * getDIByDigitalLink - retrieve data by digital link
   * @param params
   * @param queryParams
   * @author - SwapnilP
   */
  getDIByDigitalLink(params: Params = {}, queryParams: Params = {}, thirdWebParams: any = {}): Observable<any> {
    
    let combinedParams = {}
    sessionStorage.setItem('lastApiCall', 'getDIByDigitalLink');
    if(thirdWebParams  && Object.keys(thirdWebParams).length > 0){
      combinedParams = {...this.storedParams,...thirdWebParams };
      let ntToken = sessionStorage.getItem('nt-token');
      let customHeader = {
        "nt" : ntToken
      }
      const httpParams = new HttpParams({ fromObject: combinedParams });
      return this.get(`di/data/digitallink`, httpParams,null,customHeader)
     .pipe(
        map(response => {
          const data = response.data;
          return {vdi: {
            status: response.status,
            diLayout: response.status === 'activated' ? data.layout : data.inactiveLayout,
            diData: data.entityInformation,
            customGetAPIData: data.customGetAPI,
            diLanguages: data.diLanguages,
            controllerLanguages: data.controllerLanguages,
            diPrimaryLanguage: data.diPrimaryLanguage,
            redirectionUrl: data.redirectionUrl,
            environment: data.environment,
            controllerCode: data.controllerCode,
            NftVerificationResult:data.NftVerificationResult,
            ditemplateId: data.ditemplateId,
            authDomain: data.authDomain,
          },
          nfc:{
            nfc:{
              ntag_424_dna: {
                data: data.nfcTagDetails
            }
            } 
          }};
        }),
        catchError((error) => {
          console.error(error);
          return of({}); 
        })
      );
    }else{
      this.storedParams = queryParams;
      sessionStorage.setItem('wallet-address',"")
      let ntToken = sessionStorage.getItem('nt-token');
      let customHeader;
      if (ntToken) {
        customHeader = {
          "nt": ntToken
        }
      }
      const httpParams = new HttpParams({ fromObject: queryParams });
      return this.get(`di/data/digitallink`, httpParams,null,customHeader)
      .pipe(
        map(response => {
          const data = response.data;
          if(data?.nt) sessionStorage.setItem('nt-token',data?.nt);
          return {vdi: {
            status: response.status,
            diLayout: response.status === 'activated' ? data.layout : data.inactiveLayout,
            diData: data.entityInformation,
            customGetAPIData: data.customGetAPI,
            diLanguages: data.diLanguages,
            controllerLanguages: data.controllerLanguages,
            diPrimaryLanguage: data.diPrimaryLanguage,
            redirectionUrl: data.redirectionUrl,
            environment: data.environment,
            controllerCode: data.controllerCode,
            ditemplateId: data.ditemplateId,
            authDomain: data.authDomain,
          },
          nfc:{
            nfc:{
              ntag_424_dna: {
                data: data.nfcTagDetails
            }
            } 
          }};
        }),
        catchError((error) => {
          // console.log(error);
          return of({});
        })
      );
    }
    
    
  }

  /**
   * [Common error formatting function]
   * @param  error [description]
   * @return       [Observable throwError]
   * @author SapnilP
   * @task QLIKTAG-2925
   */
  private formatErrors(error: any): Observable<any> {
    console.log({ error })

    return throwError(error.error);
  }

  /**
   * [Handle GET requests]
   * @param  path   [api endpoint stub]
   * @param  params [GET request parameters]
   * @return        [Observable]
   * @author SwapnilP
   * @task QLIKTAG-2925
   * @update - Access changed from private to public
   * @author - Abhishek
   */
  public get(path: string, params: HttpParams = new HttpParams(), token?, customHeaders: object = {}): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    if(customHeaders){
      Object.keys(customHeaders).forEach(header => {
        headers = headers.append(header, customHeaders[header]);
      });
    }

    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`)
    }
    console.log(this.http.get(`${environment.apiUrl}${path}`, { params: params, headers }))
    return this.http.get(`${environment.apiUrl}${path}`, { params: params, headers })
      .pipe(catchError(this.formatErrors));
  }

  /**
   * GET by supplied path
   * @param path 
   * @param params 
   * @param token
   * @param headerAddOn additional http header data.
   * @author Nei C 
   * @returns 
   */
  public getByPath(path: string, params: HttpParams = new HttpParams(), token?, headerAddOn?:Record<string,string>): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`)
    }
    if(headerAddOn){
      Object.keys(headerAddOn).forEach(key=>{
        if(headerAddOn[key]){
          headers = headers.append(key,headerAddOn[key]);
        }
      })
    }
    return this.http.get(`${path}`, { params: params, headers })
      .pipe(catchError(this.formatErrors));
  }

  postMultipart(path: string, formData: FormData, urlKey: string = 'apiUrl',xsrfToken: string, options?): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('X-XSRF-TOKEN', xsrfToken);

    const httpOptions = {
      headers: headers,
      ...options
    };

    console.log(formData);
    return this.http.post(`${this.getEndpoint(urlKey)}${path}`, formData, httpOptions);

  }

  getEndpoint(urlKey?: string) {
    let endpoint = `${environment.apiUrl}`;

    // if(urlKey === 'adminApiUrl') {
    //   endpoint =  `${environment.adminApiUrl}`;
    // }

    return endpoint;

  }

  /**
   * [Handle POST requests]
   * @param  path [api endpoint stub]
   * @param  body [request body]
   * @return      [Observable]
   * @author Lalitkumar Saindane
   * @task QLIKTAG-3376
   */
  post(path: string, body: Object = {}, token?): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`)
    }
    return this.http.post(
      `${environment.apiUrl}${path}`,
      body,
      {
        headers
      }).pipe(catchError(this.formatErrors));
  }

  /**
   * 
   * @param path 
   * @param body 
   * @returns 
   */
  patch(path: string, body: Object = {}, token?, xsrf?, patchForm?) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`)
    }

    if(xsrf){
      headers = headers.append('X-XSRF-TOKEN', xsrf)
    }

    if(patchForm){
      headers = headers.append('Patch-Form', patchForm)
    }

    return this.http.patch(
      `${environment.apiUrl}${path}`,
      JSON.stringify(body),
      {
        headers,
      }
    ).pipe(catchError(this.formatErrors));
  }
}



