import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Page } from '../interfaces/page';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  redirectionURL: string;
  renderPage: boolean = true;   // if this is set to false, main component will redirect to url stored in redirectionURL
  variantDisplayIndex: number = 0;
  listItemMap: {[key: string]: Array<any> } = {};
  public diLanguages = [];
  public controllerLanguages = [];
  private _listIndex: string;
  public get listIndex() {
    return this._listIndex;
  }
  public set listIndex(listIndex) {
    this._listIndex = listIndex;
  }
  public headerControl: {[key: string]: any} = {};
  public footerControl: {[key: string]: any} = {};

  public contentControls = [];
  public currentPage$ = new BehaviorSubject<Page>({} as Page);
  public diLayout$ = new BehaviorSubject<any>({});
  public appLang$ = new BehaviorSubject<string>('en');
  private templateLocalVariables = {
    data: null
  };

  public get lv() {
    return this.templateLocalVariables;
  }

  public set lv(data) {
    this.templateLocalVariables['data'] = {
      ...this.templateLocalVariables['data'],
      ...data};
  }


  private _pageAlias: string = '';
  public get pageAlias() {
    return this._pageAlias;
  }
  public set pageAlias(alias: string) {
    this._pageAlias = alias;
  }

  private _detailPageAlias: string = '';
  public get detailPageAlias() {
    return this._detailPageAlias;
  }
  public set detailPageAlias(alias: string) {
    this._detailPageAlias = alias;
  }



  // Getters and Setters for customGetAPIData, diLayout, diData, routeData
  private _customGetAPIData: any = {};
  public get customGetAPIData() {
    return this._customGetAPIData;
  }
  public set customGetAPIData(customGetAPIData) {
    this._customGetAPIData = customGetAPIData;
  }
  private _diLayout: any = {};
  public get diLayout() {
    return this._diLayout;
  }
  public set diLayout(diLayout: any) {
    this._diLayout = diLayout;
    this.diLayout$.next(this._diLayout);
  }
  private _diData = {};
  public get diData() {
    return this._diData;
  }
  public set diData(diData: any) {
    this._diData = diData;
  }


  private _smartLabelData = {};
  public get smartLabelData() {
    return this._smartLabelData;
  }
  public set smartLabelData(data: any) {
    this._smartLabelData = data;
  }




  private _routeData = {};
  public get routeData() {
    return this._routeData;
  }
  public set routeData(routeData: any) {
    this._routeData = routeData;
  }

  // Added by Abhishek, Task: QLIKTAG-3253 
  //Default set to false
  public isMainHeaderEnabled: boolean = false;

  private _appLanguages: any;
  
  public get appLanguages() {
    return this._appLanguages;
  }

  public set appLanguages(appLanguages: any) {
    this._appLanguages = appLanguages;
  }

  // Added by Abhishek, Task: QLIKTAG-3150 
  private _navigationType: string = "1" //1 = hamburger 2 = action sheet : Default = 1
  public get navigationType() {
    return this._navigationType;
  }
  public set navigationType(navigationType) {
    this._navigationType = navigationType;
  }

  /**
   * [ Getter function to read pageMode ]
   * @return [pageMode in page as string]
   * @author - SwapnilP
   * @task - QLIKTAG-2925
   */
  public get pageMode(): string {
    const page = this.currentPage$.getValue();
    return this.getPageMode(page);
  }

  isDigitalLinkInteraction: boolean = false;
  ditemplateId: string;
  diinstanceId: string;
  entityCode: string;
  
  public styles: any = {
    menu: {
      fabBgColor: '#3880ff'
    }
  };

  translations = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute) {
  }

  /**
   * [Sets three sections of the current page]
   * @return [nothing]
   * @author - SwapnilP
   * @task - QLIKTAG-2925
   */
  render(page: Page) {
    this.contentControls = page.controls;
  }
  /**
   * @author Neil C
   * @return [https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object]
   */
  renderHeader() {
    this.headerControl = {};
    if (this.diLayout.hasOwnProperty('isHeaderEnabled') && this.diLayout.isHeaderEnabled) {
      if (this.diLayout.hasOwnProperty('header') && this.diLayout.header && Object.keys(this.diLayout.header).length) {
        this.headerControl = {
          ctrlName: 'main-header',
          config: {
            ...this.diLayout.header
          }
        };
      }
    }

    // this.isMainHeaderEnabled  = false;

    // let showLanguageSelector: boolean = false
    // if (this.diLayout.hasOwnProperty('showLanguageSelector')) {
    //   showLanguageSelector = this.diLayout.showLanguageSelector
    // }

    // if (this.diLayout.pages && this.diLayout.pages.length > 1 || showLanguageSelector) {

    //   this.isMainHeaderEnabled  = (this.diLayout.pages.length > 1 && this.navigationType == '1' ) || showLanguageSelector ? true : false;
    //   this.headerControl = {
    //     ctrlName: 'header',
    //     config: {
    //       isContentHeader: false,
    //       isMenuButtonVisible: this.navigationType == '1' ? true : false,
    //       showLanguageSelector: showLanguageSelector
    //     }
    //   };
    // }
  }

  /**
   * @author NeilC
   * @return [https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object]
   */
  renderFooter() {
    this.footerControl = {};
    if (this.diLayout.hasOwnProperty('isFooterEnabled') && this.diLayout.isFooterEnabled) {
      if (this.diLayout.hasOwnProperty('footer') && this.diLayout.footer && Object.keys(this.diLayout.footer).length) {
        this.footerControl = {
          ctrlName: 'main-footer',
          config: {
            ...this.diLayout.footer
          }
        };
      }
    }
  }

  /**
   * [sets given page as current page]
   * @param  alias [alias string of page]
   * @return       [nothing]
   * @author - SwapnilP
   * @task - QLIKTAG-2925
   */
  setCurrentPage(page: Page) {
    this.currentPage$.next(page);
  }

  /**
   * [pageLookup finds and returns page with given alias and pageIndex from provided pages Array]
   * @param  alias     [string alias of a page]
   * @param  pages     [Pages array in which searching is done]
   * @param  pageIndex [pageIndex of a page to return page]
   * @return           [page or blank object if page is not found]
   */
  pageLookup(alias: string, pages: Array<Page>, pageIndex?: number): Page {
    if (pageIndex > 0) {
      return pages[pageIndex - 1];
    }
    const foundPages = pages.filter((page) => page.alias === alias);
    return foundPages.length > 0 ? foundPages[0] : {} as Page;
  }
  
  /**
   * [decides mode of the page and returns it]
   * @param  page [page object]
   * @return mode [ mode of page]
   * @task - QLIKTAG-3146
   * @author - SwapnilP
   */
  getPageMode(page): string {
    let mode = 'standard';
    // console.log(page.hasOwnProperty('mode') && page.mode);
    if (page) {
      if (page.controls && Array.isArray(page.controls) && page.controls.some((control) => control.ctrlName === 'smartlabel')) {
        mode = 'smartlabel';
      } else if (page.hasOwnProperty('mode') && page.mode) {
        mode = page.mode;
      }
    }
    return mode;
  }

  /**
   * [Method to get changed language from language selector]
   * @return nothing
   * @task QLIKTAG-3253
   * @author Abhishek
   */
  onLanguageChange(languageCode: string = null) {
    // Old code
    // let params = new URLSearchParams(location.search);
    // params.set("lang", languageCode);
    // location.search   = params.toString();

    // 1 use query param change observable
    this.router.navigate([], {queryParams: {lang: languageCode}, queryParamsHandling: 'merge', preserveFragment: true});
  }

  /**
   * [menuIcon for Hamburger]
   * @return ion-icon tag
   * @task QLIKTAG-3350
   * @author Lalitkumar Saindane
   */
  menuIcon(name: string): string {
    let result = '';
    let mode;
    let nameArray = [];
    let iconName;
    if (name) {
      nameArray = name.split('-');
      mode = nameArray.shift();
      iconName = nameArray.join('-');
      result = `<ion-icon name="${iconName}" mode="${mode}"></ion-icon>`;
    }

    return result;
  }

  /**
   * [menuIcon for actionSheet]
   * @return icon
   * @task QLIKTAG-3350
   * @author Lalitkumar Saindane
   */
  actionSheetIcon(name: string): string {
    let result = '';
    let nameArray = [];

    if (name) {
      nameArray = name.split('-');
      result = nameArray.slice(0).join('-');
    }

    return result;
  }
}
